@if (!article) {
  <div class="v-center">
    <br />
    <app-spinner></app-spinner>
  </div>
}

@if (article) {
  <div id="block">
    <div id="underbox" [ngClass]="homePageStyling ? 'home': ''">
      @if (article.franchise && showFranchise) {
        <a id="franchise"
          routerLink="/reports/franchise/{{article.franchise.identifier ?? article.franchise.id}}">
          <i [responsiveBackgroundImage]="article.franchise.square_logo || article.franchise.image"></i>
          {{article.franchise.name}}
        </a>
      }
      @if (haveAccess) {
        <a [routerLink]="articleUrl(article)">
          @if (showImage) {
            <div [ngClass]="homePageStyling ? 'image home-page': 'image'" [responsiveBackgroundImage]="article.lead_image"
              >
            </div>
          }
        </a>
      }
      @if (!haveAccess && billingEnabled) {
        <a href="{{joinUrl}}">
          @if (showImage) {
            <div [ngClass]="homePageStyling ? 'image home-page': 'image'" [responsiveBackgroundImage]="article.lead_image"
              >
            </div>
          }
        </a>
      }
      <div id="metadata" [ngClass]="homePageStyling ? 'metadata-home-page': ''">
        @if (article.badge) {
          <div class="badge">{{article.badge}}</div>
        }
        <div [ngClass]="homePageStyling ? 'article-title home-page': 'article-title'">
          @if (haveAccess) {
            <a [routerLink]="articleUrl(article)">
              @if (!article.is_published) {
                [DRAFT]
              }
              {{article.feed_headline || article.title}}
            </a>
          }
          @if (!haveAccess) {
            <a href="{{joinUrl}}">{{article.feed_headline || article.title}}</a>
          }
        </div>
        <div [ngClass]="homePageStyling ? 'summary home' : 'summary'">
          {{article.feed_summary}}
        </div>
        <div class="article-subtext">
          @if (article.members_only) {
            <span class="members-only-container">
              <tyt-plus-tag></tyt-plus-tag>
            </span>
          }
          @if (article.talent && article.talent.length > 0) {
            <strong class="by">By </strong>
            <span>
              @for (talent of article.talent; track talent; let i = $index) {
                @if (haveAccess) {
                  <a id="talent" [routerLink]="(talent.username && shouldShowTalentUser) ? '/@'+talent.username : '/about/talent/'+talent.id">@if (i > 0) {
                    <span class="comma"
                    >,&nbsp;</span>
                  }{{talent.name}}</a>
                }
                @if (!haveAccess) {
                  <a id="talent" href="{{joinUrl}}">@if (i > 0) {
                    <span class="comma"
                    >,&nbsp;</span>
                  }{{talent.name}}</a>
                }
              }
            </span>
            <span class="divider">|</span>
          }
          @if (showFranchiseInByLine && article.franchise) {
            <span>{{article.franchise.name}}</span>
            <span class="divider">|</span>
          }
          @if (date) {
            <time datetime="date">{{date | date}}</time>
            <span class="divider">|</span>
          }
          <tyt-comments-count identifier="article_{{article.id}}"></tyt-comments-count>
        </div>
      </div>
    </div>
  </div>
}