@if (article) {
  <article class="mat-light-theme">
    @if (article.superhead) {
      <div class="superhead">
        {{article.superhead}}
      </div>
    }
    <h1>{{article.title}}</h1>
    @if (article.subhead) {
      <h2 class="subhead">{{article.subhead}}</h2>
    }
    <div class="byline-container">
      <div class="byline">
        @if (article.talent && article.talent.length > 0) {
          @if (author.thumbnail_landscape1 || author.thumbnail_portrait1) {
            <div class="avatar" [responsiveBackgroundImage]="author.thumbnail_landscape1 || author.thumbnail_portrait1">
            </div>
          }
        }
        <ul class="stats">
          @if (article.talent && article.talent.length > 0) {
            @if (author) {
              <li class="author">
                <strong>By:&nbsp;</strong>
                <a [routerLink]="(author.username && shouldShowTalentUser) ? '/@'+author.username : '/about/talent/'+author.id">{{author.name}}</a>
              </li>
            }
            @for (otherAuthor of otherAuthors; track otherAuthor) {
              <strong>,&nbsp;</strong>
              <a [routerLink]="(author.username && shouldShowTalentUser) ? '/@'+otherAuthor.username : '/about/talent/'+otherAuthor.id">{{otherAuthor.name}}</a>
            }
          }
          <li [class.left-separator]="article.talent && article.talent.length > 0"><time
          [dateTime]="article.publish_date">{{article.publish_date | date : 'mediumDate'}}</time></li>
          @if (showEditLink) {
            <li class="left-separator">
              <a routerLink="/reports/edit/{{article.id}}">Edit</a>
            </li>
          }
        </ul>
      </div>
      <div class="spacer"></div>
      <div class="social-buttons">
        <tyt-social-buttons></tyt-social-buttons>
      </div>
    </div>
    <div class="lead-image-element">
      <header class="lead-image" responsiveBackgroundImage="{{article.lead_image}}"></header>
      <img class="lead-image-fallback" [src]="article.lead_image" />
      <caption>
        @if (article.lead_image_caption) {
          <span
            class="cms-content"
          [innerHTML]="article.lead_image_caption | markdownToHtml | trustHtml"></span>
          } &nbsp;
          @if (article.lead_image_credit) {
            <span
              class="cms-content"
            [innerHTML]="article.lead_image_credit | markdownToHtml | trustHtml"></span>
          }
        </caption>
      </div>
      @if (article.franchise?.is_branded_content) {
        <div class="branded-disclaimer">
          Products featured here are selected by our partners at StackCommerce. If you buy something
          through links
          on our site, you'll be helping to keep the lights on and the cameras rolling at TYT. Thanks!
        </div>
      }
      <!--start view block -->
      <tyt-content-view [blocks]="ContentTypes.contentBlocksFromApi(article.blocks)"></tyt-content-view>
      <!--end view block-->
      <div class="footer-share">
        <div>
          Enjoy this content? Help support independent journalism by spreading the word.
        </div>
        <tyt-social-buttons></tyt-social-buttons>
      </div>
      <div class="franchise-link-container">
        @if (article.franchise) {
          <a class="franchise" routerLink="/reports/franchise/{{article.franchise.identifier ?? article.franchise.id}}">
            <i [responsiveBackgroundImage]="article.franchise.square_logo || article.franchise.image"></i>
            More from {{article.franchise.name}}
          </a>
        }
      </div>
    </article>
    @if (showEditLink) {
      <div class="actions">
        <a routerLink="/reports/edit/{{article.id}}">Edit Article</a>
      </div>
    }
  }
