import { Component, OnInit, Input, inject } from '@angular/core';
import { UserService } from '@tytapp/user';
import { BaseComponent } from '@tytapp/common';
import { ApiAbbreviatedArticle, ApiArticle, ApiUser } from '@tytapp/api';
import { HostApi } from '@tytapp/common';
import { ArticlesService } from '../articles.service';

@Component({
    selector: 'tyt-article-list-item',
    templateUrl: './article-item-view.component.html',
    styleUrls: ['./article-item-view.component.scss']
})
export class ArticleItemViewComponent extends BaseComponent implements OnInit {
    private hostApi = inject(HostApi);
    private userService = inject(UserService);
    private articlesService = inject(ArticlesService);

    constructor(
    ) {
        super();
    }

    @Input()
    user: ApiUser;

    @Input()
    public article: ApiAbbreviatedArticle;

    @Input()
    public limit = null;

    @Input()
    public showImage = true;

    @Input()
    public showFranchise = true;

    @Input()
    public showFranchiseInByLine = false;

    @Input()
    public homePageStyling: boolean = false;

    audienceType: string = 'visitor';

    get date() {
        if (!this.article || !this.article.publish_date)
            return null;

        let date = new Date(this.article.publish_date);
        if (Number.isNaN(Number(date)))
            return undefined;

        return date;
    }

    get items() {
        let items = [];

        if (this.limit > 0) {
            items = items.slice(0, this.limit);
        }
        return items;
    }

    get billingEnabled() {
        return this.hostApi.capabilities.includes('web_billing:membership')
            || this.hostApi.capabilities.includes('platform_billing:membership');
    }

    async init() {
        this.subscribe(this.userService.userChanged, (user) => {
            this.user = user;
        });
        this.audienceType = this.userService.audienceType;

        this.isOpenBeta = await this.shell.hasFeature('apps.web.nation_open_beta');
    }

    get joinUrl() {
        return `/join/membership`;
    }

    get haveAccess() {
        return (this.user && this.user.premium) || !this.article.members_only;
    }
    isOpenBeta: boolean;
    get shouldShowTalentUser() {
        if(!this.user) return false;
        if (this.isOpenBeta || (!this.isOpenBeta && this.user.can_access_tytnation)) {
            return true;
        }
        return false;
    }

    articleUrl(article: ApiArticle) {
        return this.articlesService.getArticleUrlSync(article);
    }

}
