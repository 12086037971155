import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from "@angular/router";
import { ApiArticle, ApiFranchise, ArticlesApi, FranchiseApi } from '@tytapp/api';
import { ChatModule } from '@tytapp/chat';
import { TYTCommonModule } from "@tytapp/common";
import { CommonUiModule } from "@tytapp/common-ui";
import { ContentModule } from '@tytapp/content';
import { SlugService, SlugsModule } from '@tytapp/slugs';
import { ArticleItemViewComponent } from './article-item-view/article-item-view.component';
import { ArticleViewComponent } from "./article-view/article-view.component";
import { ArticlesService } from './articles.service';
import { FranchisesService } from './franchises.service';

const COMPONENTS = [
    ArticleViewComponent,
    ArticleItemViewComponent
];

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        CommonUiModule,
        TYTCommonModule,
        RouterModule,
        FormsModule,
        ChatModule,
        DragDropModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        TextFieldModule,
        MatMenuModule,
        ContentModule,
        SlugsModule
    ],
    exports: COMPONENTS
})
export class ArticlesModule {
    private articlesApi = inject(ArticlesApi);
    private articles = inject(ArticlesService);
    private franchiseApi = inject(FranchiseApi);

    constructor() {
        SlugService.registerType<ApiArticle>({
            type: 'CMS::Article',
            friendlyName: 'Article',
            getContent: slug => this.articlesApi.get(slug.slug).toPromise(),
            getUrl: slug => `/reports/${slug.slug}`,
            getMetadataForContent: async content => ({
                title: content.title,
                url: await this.articles.getArticleUrl(content, false)
            }),
        });
        SlugService.registerType<ApiFranchise>({
            type: 'CMS::Franchise',
            friendlyName: 'Franchise',
            getContent: slug => this.franchiseApi.get(slug.slug).toPromise(),
            getUrl: slug => `/reports/franchise/${slug.slug}`,
            getMetadataForContent: async content => ({
                title: content.name,
                url: `/reports/franchise/${content.identifier ?? content.id}`
            }),
        })
    }

    static forRoot(): ModuleWithProviders<ArticlesModule> {
        return {
            ngModule: ArticlesModule,
            providers: [
                ArticlesService,
                FranchisesService
            ]
        }
    }
}
